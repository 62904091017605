<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="article">
            <p style="text-align: center;">
                <strong>好劲抽隐私政策</strong>
            </p>
            <p>
                <strong>版本更新提示</strong>
            </p>
            <p>
                为了更好地保障您的权益，我们于近日更新了《好劲抽隐私政策》，本次更新的内容主要包括
            </p>
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;1.优化了部分信息的获取与使用规则表述。
            </p>
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;2.更新隐私中心查看或操作路径。
            </p>
            <p>
                请您在使用/继续使用好劲抽产品与/或服务前仔细阅读，充分理解全文，如有任何疑问或问题，请通过第8条中的联系方式与我们联系。
            </p>
            <p>
                <strong>阅读提示</strong>
            </p>
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;(1)请您在使用/继续使用好劲抽的产品和/或服务前仔细阅读、充分理解《好劲抽隐私政策》完整版全文，并在同意全部内容后使用/继续使用。除非另有说明，不同意《好劲抽隐私政策》或其更新(我们将及时通知您此类更新)可能会影响您正常使用或继续正常使用我们的部分产品和/或服务。
            </p>
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;(2)为便于您快速了解《好劲抽隐私政策》内容我们将通过<span style="color: rgb(0, 112, 192);">《好劲抽隐私政策摘要》</span>向您简单、直观地说明我们是如何处理和保护您的个人信息，更多有关我们对您的个人信息的处理规则，请查阅完整版《好劲抽隐私政策》。
            </p>
            <p>
                <strong>导言</strong>
            </p>
            <p>
                感谢您信任并使用好劲抽公司的产品和服务!好劲抽公司(即深圳万恒云科技有限公司(注册地为深圳市罗湖区东晓街道办绿景社区布吉路富基帕克大厦5层10-D22)及其关联方，以下简称&quot;我们&quot;)深知个人信息对您的重要性，并将会尽全力保护您的个人信息安全可靠。在您使用我们的产品或服务前，请像仔细阅读并充分理解以下条款，<strong>特别提醒您应留意本隐私政策中加粗形式的条款内容(与您的权益可能存在重大关系以及涉及个人敏感信息的处理)。如果您不同意本隐私政策，您可能无法正常使用我们的产品、服务。</strong>
            </p>
            <p>
                我们的所有产品和服务，包括但不限于各产品网站(如https://m.haojingchou.cn/)、PC端、TV端车载端、App(包括但不限于IOS版、Android版)，Ipad端以及小程序等全部终端客户端，均适用本隐私政策:但如该产品或服务单独设置了隐秘条款或隐私协议，则单独的隐私条款或隐私协议优先适用:单独的隐私政策/隐私协议未提及的内容，适用本隐私政策。请您注意，本隐私政策仅适用干我们所收集的您的个人信息，并不适用于任何第三方对您的个人信息的收集，以及任何第三方提供的服务或第三方的信息使用规则，我们对任何第三方收集，储存和使用的您个人信息的行为在法律允许的范围内亦不承担任何责任，因此，在使用第三方服务时请您留意和仔细阅读第三方向您展示的相关用户协议和隐私政策，并要善保管和谨慎提供您的个人信息。
            </p>
            <p>
                <strong>您同意本隐私政策表示您已经了解并同意在相关场景下，为实现功能运行，我们将对您的相关个人信息进行处理。但这并不代表只要您开始使用我们的产品与/或服务，我们将处理本隐私政策中涉及的全部个人信息:仅当您使用特定功能时，我们才会根据&quot;最小必要&quot;原则，为实现向您提供服务的目的，处理您的相关个人信息。</strong>
            </p>
            <p>
                除本隐私政策外，在特定场景下，我们将以及时告知的方式(包括但不限于更新本隐私政策，重新签署文件，页面提示，弹意，站内信，邮件，网站公告或其他便于您获知的方式)另行向您详细说明对应信息的处理目的、方式、范围等规则。
            </p>
            <p>
                本隐私政策主要向您说明以下信息：
            </p>
            <p>
                <span style="color: rgb(0, 112, 192);">1.我们如何收集和使用您的个人信息</span>
            </p>
            <p>
                <span style="color: rgb(0, 112, 192);">2.我们如何使用Cookie以及同类技术</span>
            </p>
            <p>
                <span style="color: rgb(0, 112, 192);">3.我们如何共享，转让和公开披露您的个人信息</span>
            </p>
            <p>
                <span style="color: rgb(0, 112, 192);">4.您如何了解和控制自己的个人信息</span>
            </p>
            <p>
                <span style="color: rgb(0, 112, 192);">5.我们如何存储和保护您的个人信息</span>
            </p>
            <p>
                <span style="color: rgb(0, 112, 192);">6.我们如何保护未成年人的个人信息</span>
            </p>
            <p>
                <span style="color: rgb(0, 112, 192);">7.本隐私政策如何更新</span>
            </p>
            <p>
                <span style="color: rgb(0, 112, 192);">8.如何与我们联系</span>
            </p>
            <p>
                <span style="color: rgb(0, 112, 192);">9.其他</span>
            </p>
            <p>
                <br/>
            </p>
            <p>
                <strong>1.我们如何收集和使用您的个人信息</strong>
            </p>
            <p>
                <br/>
            </p>
            <p>
                为帮您快速了解我们关于您的个人信息收集、使用的主要情况，我们以清单的方式进行了说明，您可以点击查看<span style="color: rgb(0, 112, 192);">《好劲抽个人信息收集清单》</span>。
            </p>
            <p>
                我们收集和使用的您的个人信息类型会因产品/服务的内容不同而有所区别，具体以产品/服务实际提供为准，为提供完善的体验，我们会不断改进我们的产品和服务，并可能因此需要收集和使用新的
            </p>
            <p>
                个人信息或变更个人信息使用目的或方式。如果某一需要收集和使用您的个人信息的功能或场景未能在本隐私政策中予以说明的，或者我们超出了与收集您的个人信息时所声称的目的及具有直接或合理关联范围的，我们会再次向您告知，并根据法律法规的相关要求征得您的同意。
            </p>
            <p>
                请您理解，部分个人信息的获取或收集需要您授予我们特定系统权限方可实现，您可以通过查看<span style="color: rgb(0, 112, 192);">《好劲抽应用权限》</span>说明了解我们基于您授予的系统权限而收集的信息洋情，为避免行文繁琐。下文涉及相关个人信息收集规则时对于权限申请将不再整述，位置、相机、相册(存储)、麦克风、日历等系统权限并不会默认开启，只有在您触发相关业务功能时，我们才会向您申请相关权限并获取相应信息(如涉及)。请您放心，我们申请访问的权限只限于实现特定的功能所必需，并且您可以随时微回系统权限的授权。您可以通过设备设置页面相应权限进行管理。同时，您也可以通过&quot;侧边栏一隐私中心一隐私管理-权限设置开启/取消相应权限。您开启该权限即视为您授权我们可以访问、获取，收集，使用您的相应个人信息:当您取消该授权后，我们将不再收集和使用该信息，也无法再为您提供上述与之对应的服务;但除非您依照法律的规
            </p>
            <p>
                若删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的个人信息的处理存储。如您拒绝或撤回授权系统权限，会使您无法使用相应功能，但并不影响您正常使用产品与/或服务的其他功能。
            </p>
            <p>
                通常情况下，我们会在以下场景收集和使用您的个人信息:
            </p>
            <p>
                1.1.帐号注册、登录与绑定
            </p>
            <p>
                1.1.1当您使用手机号码登录我们的产品时，我们将收集您的手机号码以便为您创建与该手机号码绑定的好劲抽帐号。手机号码是履行国家法律法规关于网络实名制(互联网用户帐号真实身份信息认证)要求的必要信息，如果您拒绝提供手机号码用干注册，登录，我们可能无法为您提供信息发布(包括发布图文、音视频、评论等)功能，但不影响您使用浏览等功能。
            </p>
            <p>
                1.1.2.基于我们与通信运营商的合作，当您使用一键登录功能时，经过您的同意，我们会使用运营商提供的功能识别您设备中的手机号码并为您创建与该手机号码绑定的好劲抽帐号，以此使您免于密码或动态验证码核验，为您提供快捷的注册，登录服务，如果您不暑望使用一键餐录功能，可以通讨其他方式注册、登录我们的产品。
            </p>
            <p>
                1.1.3.您也可以使用第三方帐号注册、登录我们的产品。此时您需要授权我们获取您在第三方平台的信息(头像、昵称等公开信息以及您授权的其他信息)，用于生成与该第三方帐号绑定的好劲抽帐号，使像可以直接注册，餐录并他用好劲抽产品和相关服务。部分情况下，我们需要重新验证并绑定您的手机号码，以确认该第三方帐号的真实性和关联性。当您使用邮箱或用户名及密码登录时，我们会收集您的邮箱及用户名，邮箱和用户名作为您的个人信息将用以完善您的网络身份信息
            </p>
            <p>
                1.1.4.您理解并同意，由于上述用于登录的手机号码，第三方帐号与好劲抽帐号的绑定关系，为便于我们基于好劲抽帐号在不同产品中向您提供一致化服务、维护平台及其他用户的人身财产安全或为了便于您有用更加顺畅且更具针对性的使用体验，我们将与适用好劲抽帐号体系的全部产品(包括好劲抽、好劲抽直播等好劲抽及好劲抽关联方产品)共同收集您登录，使用产品的个人信息，我们可能会联动展示或共享您在同一好劲抽帐号下的以下信息(根据产品特点和功能场景，不同产品中具体呈现的内容可能会有不同):
            </p>
            <p>
                (1)您的头像，昵称，绑定状态和您根据自身需求填写的基本资料(包括简介、性别、生日、地区等，详见本隐私政策第1.3条约定):
            </p>
            <p>
                (2)您的真实姓名、<strong>身份证件号码(包括身份证、港澳居民来往内地通行证、护照、台湾居民来往大陆通行证)</strong>、手机号码信息。其中身份证件号码为个人敏感信息，我们将仅限于特定场景(基于法律规定或有权的行政或司法机关要求使用好劲抽帐号体系的产品运营方提供您的实名认证信息时)进行共享或用于身份核验
            </p>
            <p>
                (3)您的内容互动记录(包括发布内容、<strong>浏览记录、评论记录、点赞记录、收藏记录、下载记录，播放记录</strong>)，您的关注关系(包括关注列表、粉丝列表、黑名单)。您的充值记录及余额，您登录、使用帐号的其他信息，以便我们为您提供更加顺畅且更具针对性的使用体验
            </p>
            <p>
                <strong>1.2.实名认证</strong>
            </p>
            <p>
                1.2.1为符合法律法规相关要求，确保用户身份真实性、实现反欺诈及其他风险控制，保障您或其他用户的安全，或维护功能/服务的安全稳定以及便干您参与专属优事活动或使用专属功能及服务，在您申请提现或使用其他需实名认证的功能或服务时或您主动进行实名认证时，我们需要收集您提供的真实身份信息，包括<strong>真实姓名、身份证件及其号码(身份证、港澳居民来往内地通行证。护照或台湾居民来往大陆通行证)、手机号码、静态或动态的面部特征信息</strong>(根据实际情况，不同场景下收集的真实身份信息可能不同，且我们仅获得人脸识别的校验结果并不保留相关信息)，以完成家名认证，在实名认证的讨程中，可能需要中提供认证服务的第三方认证机构(北京旷视科技有限公司、腾讯云计算(北京)有限责任公司)通过人脸识别的方式核验您的真实身份信息。这些信息仅供完成家名认证的目的，或其他法律法规所规定的用途未经您明示授权不会用作其他目的。若您柜绝提供，将可能无法使用必师业名认证才口使用的功能或相关服务，但不影响您使用其他功能与服务。在您实名认证或身份认证成功后，若无正当事由，实名信息将无法像改:若确需修改，请您按照本隐私政策第8条的联系方式与我们联系解决
            </p>
            <p>
                1.2.2.基于法律法规的相关要求，您的实名认证
            </p>
            <p>
                信息还可能被我们用于青少年/儿童保护，防沉迷相关的产品或服务。
            </p>
            <p>
                <strong>1.3帐号资料维护</strong>
            </p>
            <p>
                1.3.1.我们会在您登录时为您生成好劲抽帐号，您可以自主填写您的昵称、头像、性别、地区，生日，职业，兴趣爱好，以完善您的个人
            </p>
            <p>
                信息。这些资料将帮助我们更好地了解您并为您提供更优质的服务，若您未填写或后续删除、变更此类信息的，将会影响我们基于您的用户回像信息提供的您感兴趣的内容、广告，但不会影响您正常使用好劲抽帐号及服务。
            </p>
            <p>
                1.3.2.您可以申请特殊身份或职业认证(大学生、医生、教师等，具体以我们产品功能页面展示为准)以及人，好劲抽达大师认证，除根据申请页面提示完成实名认证外，我们还需要收集您向我们提供的认证所需信息及证明文件，包括姓名，学校名称，院系、入学年份、手机号码、教学科目、工作单位，所在岗位，<strong>身份证件及其号码(身份证、港澳居民来往内地通行证、护照或台湾居民来往大陆通行证) 学生证，识业证件电用于证明身份职业可技能、专长的其他文件</strong>及相关信息、主播类型、简介、头像、QQ、电子邮箱、地址、性别、地区，流派风格、教育水平、就读院校、毕业时间。我们将在审核通过后为您完成相应认证，认证标记将在您的帐号中公开展示。
            </p>
            <p>
                1.3.3您的个人信息(好劲抽ID，等级，性别、地区、年龄、个性签名，其等级根据您的帐号注册时间和参与次数生成，根据您的参与按平台规则生成)、勋章、关注数和
            </p>
            <p>
                关计列表 粉丝数和粉丝列表 访客数和访客列
            </p>
            <p>
                表、您公开发布的作品、评论，您收藏的内容，动态将会在帐号的个人主页中公开展示，您可以通过&quot;侧边栏一隐私中心一隐私管理一其他设置&quot;选择不允许其他用户查看您的个人主页、关注列表、粉丝列表，
            </p>
            <p>
                1.3.4.如您已开通会员服务，您可查看您的会员身份、有效期、卡券(包括礼品卡、优惠券等):为验证您的会员身份，我们将收集您的会员状态会员时长有效期，开通及续费VIP记录，下载记录权益中奖信息。我们发布违规处罚公告时，会公布违规帐号的昵称等信息。
            </p>
            <p>
                <strong>1.4.服务内容浏览/搜索/播放</strong>
            </p>
            <p>
                1.41服务内容浏览/播放
            </p>
            <p>
                当您使用我们音视频、直接、电子读物(小说》等服务内容的浏览、播放功能时，为向您提供连续性，一致化的使用体验，我们需要收集您的设备信息、<strong>个人上网记录</strong>。前述个人上网记录包括:浏览/点击/播放记录，收藏/关注/预约记录，点赞/分享!发布记录、访问日期/时间/时长、互动的次数等。
            </p>
            <p>
                当您使用投屏服务播放视频或查看转播内容时我们需要进行本地网络链接，收集您的网络信息及 TV端硬件设备名称、厂商、设备型号，以确认您的移动客户端硬件设备与TV端硬件设备在同一局域网内实现硬件设备互联石通及完成效果话配
            </p>
            <p>
                1.4.2.服务内容搜索
            </p>
            <p>
                当您使用搜索功能时，我们会自动收集您的一些信息，包括如下个人信息:搜索的内容、浏览记录和时间，搜索的时间以及与它们互动的次数。为了提供高效的搜索服务，部分前述信息会暂时存储在您的本地设备端，并向您展示搜索历史记录，
            </p>
            <p>
                1.5.<strong>图文或视频信息制作、发布、交流互动功能</strong>
            </p>
            <p>
                1.5.1.当您在我们的部分产品或服务中使用视频剪辑创作、上传、发布、直播、跟听、发帖、平台内交流互动、点赞、评论、分享服务/功能时，除注册登录账户之外，您可能会主动提供相关图文/视频内容、<strong>互动信息(帖子、点赞、评论、分享、交流互动信息)</strong>，我们会自动收集您的上述信息，并展示您的眼称，头像，发布的信息内容。请您知悉，您发布的信息中可能包含他人的个人信息，请您务必取得他人的合法授权，避免非法泄露他人的个人信息。若您不同意我们收集上述信息，您将无法使用我们的信息发布功能，但不影响您使用我们为您提供的其他产品和服务。
            </p>
            <p>
                1.5.2.当您使用我们的部分互动功能(如互动广告)以及空间音效，跑步电台、百度AR、实名认证拍照功能时，我们需要获取您设备的传感器信息(旋转矢量传感器、磁场传感器，加速度传感器。线性加速度传感器信息)以实现前述功能
            </p>
            <p>
                1.5.3当您使用蓝牙设备或具有蓝牙功能的智能硬件产品与好劲抽产品或服务相连接或互动，开启驾驶模式，我们需要获取您设备的蓝牙权限以实现前述功能。
            </p>
            <p>
                1.5.4.另外，我们与您之间会通过消息推送、站内信、电话、短信，邮件方式进行互动。您知悉并同意，对于您在使用产品或服务的过程中提供的您的一种或多种联系方式(<strong>电子邮箱、联系电话、联系地址</strong>)，我们在运营中可能会向其中的一种或多种发送多类通知，用于用户消息告知，身份验证，安全验证、用户调研用途。您可以在我们的产品或服务中自主控制消息通知的接收机制，您可以通过侧边栏一设置-消息设置来控制您是否接收消息通
            </p>
            <p>
                知以及接收的消息通知具体类型:此外，您可以通
            </p>
            <p>
                过手机、电子邮件中提供的退订方式进行退订。
            </p>
            <p>
                <strong>1.6.下单与交付</strong>
            </p>
            <p>
                1.6.1当您在我们的产品或服务中购买商品或服务的，我们需要根据商品或服务类型收集您的交易商品或服务信息，<strong>收货人信息(收货人姓名，收货地址及其联系电话)、交易金额、支付账号、下单时间、订单商户、订单编号、订单状态、支付方式、支付状态</strong>，观影人的<strong>姓名和身份证件号码(部分演出需要实名认证，身份证件指身份证、港澳居民来往内地通行证、护照或台湾居民来往大陆通行证</strong>)，具体收集的信息以相应场景设置为准。我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全，查询订单信息，提供客户服务。
            </p>
            <p>
                1.6.2在您成功下单后，若商品系第三方卖家提供的或商品需要第三方配送，我们为了顺利、安全、准确向您完成货物的交付及配送商家及第三方配送公司在发货及配送环节内不可避免地会获知您的相关<strong>配送信息</strong>(包括收货人姓名，收货地址及其联系电话在内的个人信息)。我们向您承诺，我们会以最大努力保障您的个人信息安全，我们会严格要求商家及第三方配送公司对您的个人信息保密，只以配送之目的获悉和使用，不得对外泄露或做其他任何用途。
            </p>
            <p>
                1.6.3若您使用虚拟币充值、虚拟物品购买赠送、会员权益开通服务，我们会收集您的<strong>充值、交易、余额信息</strong>。充值，交易，余额信息属于个人敏感信息，我们将使用加密技术收集、传输、存储。
            </p>
            <p>
                <strong>1.7.参与活动</strong>
            </p>
            <p>
                当您参与活动时，如涉及您的个人信息收集，我们会在活动规则中进行说明。特别地，如涉及以邮寄方式进行奖品或与活动相关的实物发放，我们需要收集您的<strong>收货信息(收货地址、联系人名称及联系人电话)</strong>并共享给提供物流服务的第三方合作商，以实现奖品或其他实物的寄递:当您涉及红包，奖金或其他奖励发放时，法律要求我们属行代扣代缴个人所得税的义务，因此我们需要依法收集您的<strong>姓名、身份证件信息(身份证、港澳居民来往内地通行证、护照或台湾居民来往大陆通行证)</strong>。我们可能会将通过活动获得的您的相关信息，用干研发和改进产品和服务，综合统计，数据分析或加工等或经您同意的其他用途。
            </p>
            <p>
                <strong>1.8.客服、其他用户响应功能</strong>
            </p>
            <p>
                当您与我们的客服互动时或使用其他用户响应功能(在线提交意见反馈、与在线/人工客服沟通、提出我们的产品或服务的售后申请、行使您的相关个人信息控制权，其他客户投诉和需求)时，为了您的帐号与系统安全，我们需要您先行提供帐号信息，并与您之前的个人信息相匹配以验证您的用户身份。在您使用客服或其他用户响应功能时，我们还会根据场暑需要收集您的以下个人信息:联系方式(您与我们联系时使用的电话号码/电子邮箱/OC号码或您向我们主动提供的其他联系方式)、<strong>您与我们的以文字/图片/音视频/通话记录形式存在的沟通信息(敏感个人信息)</strong>。我们收集这些信息是为了调查事实与帮助您解决问题，如您拒绝提供可能导致您无法使用我们的客服及其他用户响应机制。
            </p>
            <p>
                <strong>1.9.产品安全保障功能</strong>
            </p>
            <p>
                1.9.1当您使用我们产品的注册登录，内容发布、付费购买、实名认证等功能或服务时，我们需要收集您的个人信息来保障您使用我们的产品或服务时的帐号与系统安全，并协助提升我们的产品服务的安全性和可靠性，以防产生任何危害用户、好劲抽、社会的行为，具体个人信息是指您的手机号码、帐号登录地、设备信息在应用内的使用信息、蓝牙信息、传感器信息(旋转矢量传感器、磁场传感器，加速度传感器，线性加速度传感器信息)，登录IP地址、产品版本号、语言模式、<strong>浏览记录</strong>、网络使用习惯、服务故障信息，网络日志，<strong>个人上网记录，交易信息、实名认证信息</strong>。其中设备信息包括硬件型号、设备MAC地址/网络设备标识符(WiFI的MAC地址，蓝牙MAC地IMELIMSIMEID.Androidld，硬件序列号、IDFA、OAID、Deviceld、ICCID、openUDID日志信息是指您使用我们的产品或服务时，我们自动收集并生成的操作日志(包括您的浏览记录、点赞、分享、评论、<strong>搜索词及搜索记录、互动记录、收藏、关注、下载记录、播放记录</strong>，播放时长，访问日期和时间，蓝牙耳机和智能音箱等智能外接设备的操作记录)以及您的服务日志(包括但不限于正在安装的应用信息或正在运行的进程信息、应用程序的总体运行，使用情况与频率，应用崩遗情况，总体安装使用情况，性能数据，应用来源等)。
            </p>
            <p>
                1.9.2.我们会根据上述信息来综合判断您帐号。
            </p>
            <p>
                账户及交易风险，进行身份验证、客户服务，检测及防范安全事件、诈骗监测、存档和备份用途，并依法采取必要的记录、审计、分析、处置措施。一旦我们检测出存在或疑似存在帐号安全风险时，我们会使用相关信息进行安全验证与风险排除，确保我们向您提供的产品和服务的安全性，以用来保障您的权益不受侵害。同时，当发生帐号或系统安全问题时，我们会收集这些信息来优化我们的产品和服务。
            </p>
            <p>
                1.9.3为确保您设备操作环境的安全，防止恶意程序和反作弊，我们会在您同意本隐私政策后以及需要调用，唤醒第三方应用时收集您设备上已安装或正在运行的必要的软件列表信息(包括软件包名和软件名称)或正在运行的应用的进程信息，请您知悉，单独的软件列表信息无法识别您的特定身份。
            </p>
            <p>
                <strong>1.10.免流量功能</strong>
            </p>
            <p>
                若您使用免流量功能时，为准确识别免流量用户身份及更好地记录定向应用消耗的流量，我们会通过合作方获取您的手机号码，并收集您的设备属性信息(IP地址、手机型号)、SIM卡序列号、IMSI(国际移动用户识别码)，运营商信息，用于判断是否符合免流量条件以便提供免流量服务请您注意，当您领取免流量电话卡或开通免流量服务时，您可能需要进行实名认证，并需要提供姓名、<strong>身份件证号码</strong>、联系电话、收货地址、详细地址(以实际运营方页面展示为准)。此时，好劲抽不会收集您的这些信息。提示您仔细识别实际服务的提供方，并在使用任何第三方服务之前仔细查看相关隐私政策或个人信息处理规则。
            </p>
            <p>
                1.11,<strong>服务内容推荐(个性化内容推荐/定向广告推送)、维护/改进我们的产品或服务之必需</strong>
            </p>
            <p>
                1.11.1.在我们的部分产品或服务中，我们会根据近期热点，平台热推等因素为您推荐，转播内容是我们根据平台运营策略进行的推荐。同时，为了向您实时推荐更优质、您可能更感兴趣的服务内容与广告、让您有更好的产品或服务体验或您同意的其他用途，我们还会根据您的相关个人信息、用户画像等进行个性化内容推荐/定向广告推送。在此过程中，我们会收集您的生日、性别、地区、职业、设备标识，以及您在我们的某一项产品或服务中的<strong>搜索记录、播放历史、浏览服务内容与广告的次数以及与它们互动的行为信息、点赞/分享/评论/互动信息、收藏记录、播放时长、订单信息</strong>、IP地址、访问日期和时间、您的偏向网络行为兴趣偏好、设备信息、必要的软件列表，并以综合统计、分析的方式单独或与来自其他服务的某些信息结合进行推荐算法建模、程序化广告推荐算法建模、用户行为分析及用户画像，以帮助我们更加了解您如何使用我们的产品或服务、维护和改进我们的产品或服务或向您提供个性化服务/定向推送，包括我们向您提供相关个性化信息(我们的个性化信息可能会根据信息系统、算法在内的自动化决策机制做出)，在征得您同意的情况下与我们的合作伙伴共享信息以便他们向您发送有关其产品和服务的信息。自动化决策是指通过计算机程序自动分析评估个人的行为习惯，兴趣爱好，并进行决策的活动。
            </p>
            <p>
                1.11.2.当您使用创新广告交互功能(如摇动扭动、扭转设备)时，我们会调用设备的陀螺仪加速度、重力传感器以识别判断您的设备状态。请您理解，单独的设备传感器数据不涉及任何个人位置信息且无法与其他信息结合用于识别特定自然人的身份。
            </p>
            <p>
                1.11.3我们在产品中设有个性化推荐自主管理功能，您可以通过侧边栏一隐私中心一隐私管理一个性化推荐管理/广告管理来管理相关服务内容/广告的个性化推荐。
            </p>
            <p>
                1.11.4.您授权我们有权与委托我们进行信息推广和广告投放的合作伙伴(见第三方SDK服务明细)，共享我们使用您的相关信息集合形成的，但无法识别您的个人身份信息的间接用户画像、去标识化的设备信息或匿名化处理后的分析、统计类信息，以帮助其在不识别您个人身份的前提下进行广告或决策建议、提高广告有效触达率、衡量广告和相关服务的有效性。
            </p>
            <p>
                1.12.<strong>从外部第三方间接收集的您的个人信息</strong>
            </p>
            <p>
                1.12.1.您知悉并同意，您向外部第三方(好劲抽关联方不在此限)提供的个人信息，或外部第三方收集的您的个人信息，我们无法获取，更不会使用非常规方式(如恶意干预对方App数据)擅自以软件程序获得您的个人信息。如果因业务发展的必要而需要从第三方间接收集您的个人信息的，且由我们直接或联合为您提供产品或服务的，我们(或第三方)在收集前会向您明示共享的您个人信息的来源、类型、使用目的、方式和所用于的业务功能授权同意范围(如果使用方式和范围超出您在第三方原授权范围的我们会再次征得您的授权同意范围(如果使用方式和范围报出您在第三方原授权范围的，我们会再次征得您的授权同意)，此种场景包括您通过第三方账户直接登录我们的产品或服务时，我们从第三方获取的您授权共享的账户信息(头像、呢称)，我们的某些产品/眼务由业务合作伙伴提供或者我们与业务合作伙伴共同提供时，为了必要!合理的业务的顺利开展，我们从部分业务合作伙伴处间接收集的您的部分信息其他方使用我们的产品或服务时所发布的与您相关的内容(评论、动态、图片、照片、视频)
            </p>
            <p>
                1.12.2.此外，我们会在间接收生您的个人信息前，明确以书面形式(如协议，承诺书)要求该第三方在已经向您告知其共享的信息内容并取得您的明示同意后由我们间接收集您的个人信息，且涉及您的个人敏感信息时，我们要求第三方在提供给我我们使用前需经过您的再次确认，并在协议等层面要求第三方对个人信息来源的合法性和合规性作出承诺，若第三方有违反行为的，我们会明确要求对方承担相应法律责任，我们会使用不低于我们对直接收集的用户个人信息同等的保护手段与措施对间接收集的个人信息进行保护
            </p>
            <p>
                <strong>1.13.其他</strong>
            </p>
            <p>
                1.13.1.当您使用我们的产品时，基于授权地域限制，我们会通过IP地址分析您的粗略地理位置，若分析结果显示您位于中国大陆以外的国家或地区，我们需要申请获取您的位置权限，并在您授权后通过您的GPS信息，WLAN等网络位苦信息(包括附近的WiF)，蓝牙和基站ID(celllD)等信息获取您的精确<strong>地理位置信息</strong>以判断您是否在版权授权区域内，精确地理位置信息是个人敏感信息若您不提供将可能无法继续使用某些功能，但不影哈您使用我们的其他功能和服务
            </p>
            <p>
                1.13.2.当您使用好友功能时，我们会基于您或其他用户授权的手机号码，<strong>通讯录</strong>、关注的人、因使用分享功能而产生的关系链(需结合第三方账号登录信息)向您推荐您可能认识的人及其内容，并将您或您的内容推荐给您可能认识的人，若您不看望我们基于前述功能向您推荐或被推荐给可能认识的人，您可以通过侧边栏-隐私中心-隐私管理-其他设置-开启好友匹配/允许好友找到我进行设置，请您知悉，如果其他用户上传的通讯录中包含您的联系方式，<strong>我们将向您推荐您可能认识的人及其内容，并将您和您的内容推荐给您可能认识的人。</strong>
            </p>
            <p>
                1.13.3.当您使用AI朗读，链接识曲功能，接收被分享的信息、参加活动等情形下，好劲抽客户端需要在本地访问您的剪切板，读取其中包含的文字、链接、口令码信息，好劲抽客户端仅在本地识别出些切板内容属于AI朗读，识曲链接，分享信息，活动联动等指令后才会将其上传我们的服务器，以使向您反馈相关内容。除此之外，好劲抽客户端不会上传您剪切板的其他信息至我们的服务器。只有当您在搜索框中确认填入前述信息并点击搜索后，为了实现搜索功能我们会收集该信息。有些设备的系统会提示应用(App)正在读取剪切板，但该系统提示无法区分是本地处理还是上传，且由干统计方式的差异，不排除误报的可能，如果您发现类似提示并有疑问，请与我们联系，以使我们定位问题
            </p>
            <p>
                1.13.4.我们产品应用程序(安卓版本)中已注册开机广播权限，以便通过该权限实现自启动并进一步保证充电动效功能在设备重启后正常使用。为了避免充电动效功能因长期未启动而失效，我们将申话使用您所用设备的<strong>获取通知栏权限</strong>;特别地当您使用充电动效的游戏模式或袋剧模式时，为了防止游戏或视频进程被打断，我们将申请获取您所用设备的<strong>读取应用使用情况权限</strong>。前述三项权限约不会收集或使用您的个人信息，您可以根据我们产品提示在相应场景开启获取通知栏权限，读取应用使用情况权限，也可以通过您所用设备的设置功能开启或关闭前述两项权限:前述权限关闭后可能影咱充电动效功能的正常使用，或导致您无法使用游戏模式，像剧模式，但不影响您使用我们产品或服务的其他功能。
            </p>
            <p>
                1.13.5.<strong>其他用户发布的评论、回复、照片、音频、视频中可能包含您的信息，此类信息可能因其他用户的发布、上传而被我们收集或使用。若您认为侵犯您的个人信息，请您通过下述第8条投诉方式和联系方式联系我们进行处理。</strong>
            </p>
            <p>
                1.13.6.我们可能会收集您使用我们的产品或眼务时的特定信息做一些其他合理用途，包括:调查问券回复，运营活动，您与好劲抽/我们的合作伙伴之间互动时我们获得的相关信息，以用于研发和改进、综合统计、数据分析或加工等处理，经您同意或授权的其他用途、遵守法律规定或其他一些合理用途。
            </p>
            <p>
                <strong>1.14.例外情形</strong>
            </p>
            <p>
                <strong>根据《个人信息保护法》的规定，以下情形下，我们收集、使用您的个人信息，无需事先取得您的同意:</strong>
            </p>
            <p>
                <strong>(1)为订立、履行您作为一方当事人的合同所必需;</strong>
            </p>
            <p>
                <strong>(2)为履行法定职责或者法定义务所必需</strong>
            </p>
            <p>
                <strong>(3)为成对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需:</strong>
            </p>
            <p>
                <strong>(4)为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息:</strong>
            </p>
            <p>
                <strong>(5)依照《个人信息保护法》的规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息;</strong>
            </p>
            <p>
                <strong>(6)法律、行政法规规定的其他情形。</strong>
            </p>
            <p>
                1.14.<strong>请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且收生了您的个人信息，我们会通过更新本隐私政策、启动或浏览界面提示、弹窗、网站公告、站内信、交互流程、网站公告等方式另行向您说明信息收集的内容、范围和目的</strong>，在此过程中如果您对相关事宜有任何疑惑的，可以通过本隐私政策第8条提供的方式联系我们，我们会尽快为您作出解答。
            </p>
            <p>
                <br/>
            </p>
            <p>
                <strong>2.我们如何使用Cookie以及同类技术</strong>
            </p>
            <p>
                <br/>
            </p>
            <p>
                <strong>Cookie和同类技术是互联网中普遍使用的技术。当您使用好劲抽及相关服务时，我们可能会使用相关技术收生您的信息。我们使用Cookie和同类技术主要为了实现以下功能或服务:</strong>
            </p>
            <p>
                2.1 保险产品与服务的安全、合理运转:我们可能会设置认证与保障安全性的Cookie或匿名标识符，使我们确认您是否安全登录服务，或者是否遇到盗用，故诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。
            </p>
            <p>
                2.2 帮助您获得更轻松的访问体验:使用此类技术可以帮助您省去重复您填写个人信息，输入转索
            </p>
            <p>
                内容的发布和流程(例如:表单填写)。
            </p>
            <p>
                2.3 为您推荐，展示，推送您可能感兴趣的内容:我们可能会利用Cookie和同类技术了解您的喜好和使用习惯，进行数据分析，以改善产品服务。推荐用户感兴趣的信息或功能，并优化您对广告的选择
            </p>
            <p>
                您可以通过浏览器设置拒绝或管理Cookie以及同类技术的使用:但请注意，如果停用Cookie，您可能无法享受最佳的服务体验，某些服务也可能无法正常使用
            </p>
            <p>
                <br/>
            </p>
            <p>
                <strong>3.我们如何共享、转让和公开披露您的个人信息</strong>
            </p>
            <p>
                <br/>
            </p>
            <p>
                <strong>3.1.共享</strong>
            </p>
            <p>
                我们在遵守“合法正当、最小必要、目的明确原则”的前提下共享您的个人信息
            </p>
            <p>
                如我们委托第三方处理您的个人信息的，我们将会与受托人约定委托处理的目的，期限、处理方式、个人信息的种类、保护措施以及双方的权利和义务等，并对受托人的个人信息处理活动进行监督，如果我们与第三方个人信息处理者共同决定您个人信息的处理目的和处理方式的，将与合作商约定各自的权利和义务;且该约定不影响您向其中任何一个个人信息处理者要求行使您的权利，若侵害您的个人信息权益造成损害的，我们将依法承担责任。
            </p>
            <p>
                我们将对个人信息的共享活动事先进行个人信息保护影响评估，对输出形式、流转、使用等采取有效的技术保护措施。在合作协议层面，严格要求合作商的信息保护义务与责任，与业务合作商在合作前签署关于数据安全的保护协议。
            </p>
            <p>
                我们制定了《第三方信息共享清单》向您清晰简洁的介绍我们内嵌SDK清单、与业务合作伙伴通过接口(API)等方式与第三方进行信息共享
            </p>
            <p>
                <br/>
            </p>
            <p>
                我们与第三方进行个人信息共享的情形有:
            </p>
            <p>
                3.1.1在法定情况下的共享:我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，向相关行政、司法机关共享您的个人信息。
            </p>
            <p>
                3.1.2.在获取单独同意的情况下共享:获得您/您的父母或监护人同意后，我们会与其他方共享您的个人信息。
            </p>
            <p>
                3.1.3.关联APP间共享:为便于我们基于好劲抽统一的帐号体系向您提供一致化服务，完美个性化相荐、实现安全风控、提供客户服务之需要、好劲抽产品或服务将适用统一账号体系。适用统一账号体系的关联App将同步您注册，登录、使用我们的产品与/或服务的一些个人信息，可能包括以下部分或全部信息:(1)您的设备信息;(2)您的基本资料信息，包括昵称、头像、生日、性别、所在地;<strong>(3)您的个人上网记录，包括浏览/搜索/播放/发布/互动记录;[4)交易记录，订单记录笔:(5)权益/资产信息</strong>;(6)用户画像。前述部分信息将在关联App内联动展示，且您可以在关联App中使用相关权益/资产(如适用)。
            </p>
            <p>
                3.1.4.与业务合作伙伴共享:您理解并同意，为了必要/合理的业务的开展，履行我们在相关用户协议或本隐私政策中的义务，行使我们的权利或遵守法律规定目的，我们可能需要向部分业务合作伙伴共享您的部分信息。但我们承诺我们只会共享您的必要个人信息，如果我们共享您的个人敏感信息或改变个人信息的使用及处理目的，我们会将再次征求您的授权同意。特别地，我们所提供的产品和服务中某些具体的模块或功能可能由业务合作伙伴的应用程序接口(API) 业务合作伙伴软件工具开发包(SDK)提供，或我们的产品和服务通过业务合作伙伴的应用程序接口(API)、软件工具开发包(SDK)链接至业务合作伙伴。<strong>无论何种业务合作伙伴及合作形式，我们会进行严格的安全捡测接入评估并对业务合作伙伴获取信息的SDK、应用程序接口(API)进行严格的安全监测，令其按照不低于本隐私政策以及适当的保察和安全措施来处理您的个人信息。点击查看<span style="color: rgb(0, 112, 192);">《第三方信息共享清单》</span></strong>，了解我们通过产品内嵌SDK及API接口与第三方的信息共享情况。
            </p>
            <p>
                3.1.5.我们平台的第三方商家:为实现您购买产品或服务及享受售后服务的需求，我们会将您的订单信息及与本易相关的信息井言给我们平台的第二单信息及与交易相关的信息共享给我们平台的第三方商家。当您在订购演出票务时，我们会向票务方(天建猫眼现场科技有限公司，即猫眼票务)提供您的联系信息(联系人姓名，手机号码，详纽地址)，以使向您寄送演出门票。
            </p>
            <p>
                3.1.6.与我们共同提供某项服务的合作伙伴共享:当我们与第三方合作联合推出会员服务或其他付费服务的，为解决我们或第三方与您之间的退款，纠纷等，您同意我们可以将您的相关订购信息共享给该第三方:我们与合作伙伴共同开展营销活动，为了能够向您提供正常服务和满足您的服务需求，我们会另行取得您的授权，并在您授权后将您的相关信息(具体以您的授权内容为准)同步给该合作伙伴。
            </p>
            <p>
                3.1.7.与受好劲抽委托提供某项服务的合作方共享:当我们开展客户服务、用户调研、活动奖品发故或其他需要委托第三方开展的业务时，我们可能会需要与合作方共享您的必要的用户个人信息以完成前述事官。此时，我们将采用适当方式在征得您的同意后进行共享:同时，我们会进行严格进行合作方筛选，并要求其按照不低于本隐私政策以及适当的保密和安全措施来处理您的个人信息。
            </p>
            <p>
                3.1.8.广告合作中的共享
            </p>
            <p>
                (1)广告投放:我们可能会向广告主或其委托的投放代理商提供您的设备信息、群体标签以向您推送更合适您的资讯、推广等商业性信息，
            </p>
            <p>
                (2)广告统计分析与优化:我们可能会向提供广告统计分析与优化服务的合作商提供您的设备信息，群体标签，广告曝光，浏览，点击，播放以及广告转化数据等广告相关数据，用干分析，衡量广告和相关服务的有效性，进行广告或决策建议、提高广告有效触达率，更好的为您提供优质、适合您的服务:广告主及/或其委托的代理商可能要求我们提供的述部分或全部信息，用于进行用户来源的远营分析，优化广告投放策略或改进其产品与服务。
            </p>
            <p>
                (3)请您放心，就前述广告合作中所涉信息对外提供，我们会采用去标识化、匿名化或加密算法等技术措施对敬据进行处理，确保合作商无法直接识别到您的身份。合作商根据接收的数据，通过自动化方式自行决策是否下发广告，进行统计分析与优化(包括与其合法获取的其他数据相结合进行统计分析与优化):请您知悉，对于部分广告内容，是广告主根据你在其应用/软件内的用户习惯，偏好等用户画像进行的个性化推荐未经您的授权或非法定情形下，我们从未也不会去获取您在其他应用内的行为或个人信息。
            </p>
            <p>
                <strong>3.2.个人信息转移</strong>
            </p>
            <p>
                我们不会将您的个人信息转移给任何公司、组织和个人，但以下情形除外
            </p>
            <p>
                (1)在获得您的明确同意后，我们会向其他方转移您的个人信息
            </p>
            <p>
                (2)因合井、分立、解散、被宣告破产等原因需要转移您的个人信息的，我们将向您告知接收方的名称或者姓名和联系方式，并会要求新的持有您个人信息的公司，组织继续受此政策的约束，否则我们将要求该公司、组织重新向您征求同意，
            </p>
            <p>
                <strong>3.3.公开披露:</strong>
            </p>
            <p>
                我们不会对外公开披露所收集的个人信息:如必须公开披露时，我们会向您告知此次公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的单独同意
            </p>
            <p>
                <strong>3.4.共享、转让、公开披露个人信息时事先征得授权同意的例外</strong>
            </p>
            <p>
                <strong>根据《个人信息保护法》的规定，以下情形下，我们共享、转让、公开披露您的个人信息，无需事先取得您的同意:</strong>
            </p>
            <p>
                <strong>(7)为订立、执行您作为一方当事人的合同所必需</strong>
            </p>
            <p>
                <strong>(8)为履行法定职责或者法定义务所必需</strong>
            </p>
            <p>
                <strong>(9)为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需</strong>
            </p>
            <p>
                <strong>(10)为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息:</strong>
            </p>
            <p>
                <strong>(11)依照《个人信息保护法》的规定在合理的范国内处理个人自行公开或者其他已经合法公开的个人信息;</strong>
            </p>
            <p>
                <strong>(12)法律、行政法规规定的其他情形。</strong>
            </p>
            <p>
                <br/>
            </p>
            <p>
                <strong>4.您如何了解和控制自己的个人信息</strong>
            </p>
            <p>
                <br/>
            </p>
            <p>
                我们将尽一切可能采取适当的技术手段，保证您对您个人信息的控制权，包括:您可以访问，更正修改，删除您的个人信息，获取您的个人信息副本，也可以撒回之前作出的对您个人信息的同意
            </p>
            <p>
                同时您也可以注销您的好劲抽帐号。为了方便您行使您的上述控制权，我们在产品的相关功能页面为您提供了操作指引和操作设置，您可以自行操作，若您在操作过程中有疑惑、困难，或者您无法通过上述途径和方式行使您的个人信息主体权利，您可以通过本隐私政策第8条约定的方式联系我们，我们会按照法律法规的规定及时为您处理，在了解，更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障帐号安全。<strong>一般情况下，您可随时修改自己提交的信息，但出于安全性和身份识别的考虑，您可能无法修改注册时提供的某些初始注册信息、验证信息及认证信息。</strong>
            </p>
            <p>
                4.1.访问个人信息
            </p>
            <p>
                4.1.1.进入好劲抽后，您可以在【我】-【头像】右上角【编辑资料】查看您的头像、昵称、性别，生日，地区，职业，个性等名，兴趣爱好，账号、学生认证信息(如有)。
            </p>
            <p>
                4.1.2.进入好劲抽后，您可以在我】页面分
            </p>
            <p>
                别点击【本地】、【收藏】、【云盘]、【音频】。【最近】。，【视频】、【评论】、查看您的本地或下载的信息，发布的评论，收藏的信息，已购信息以及最近参与信息。
            </p>
            <p>
                4.1.3进入好劲抽后，您可以在侧边栏点击【好劲抽商城】-右上角【我的】进入【我的】页面分别点击【我的订单]。【地址管理】查看您的订单信息、收货地址以及联系人和联系电话，
            </p>
            <p>
                4.1.5.进入好劲抽后，您可以在侧边栏点击【我的好友】进入我的好友页面，通过分别点击好友、关注、粉丝，查看您的好友、关注、粉丝信息。
            </p>
            <p>
                4.2.除个人信息
            </p>
            <p>
                一般而言，我们只会在法律法规规定或必需且最短的时间内保存您的个人信息，为便于您行使您的删除权，我们为您提供了在线自行删除和向我们提出删除申请两种方式。
            </p>
            <p>
                4.2.1.<strong>对于您的部分个人信息，我们在产品的相关功能页面为您提供了操作指引和操作设置，您口以直接进行删除。一旦您删除后，我们即会对此类信息进行删除或匿名化处理，除非法律法规另有规定，您可以通过以下方式自主删除您的个人信息:</strong>
            </p>
            <p>
                (1)进入好劲抽后，您可以在【我】页面分别点击【本地】、【收密】、【最近】、【已购】、【评论】 删除您的木地或下载的信息，发表预测、发布的评论，制作的竖屏MV;收藏的信息，听书或看书信息，私人云盘，已购信息，以及最近播放信息。
            </p>
            <p>
                (2)进入好劲抽后，您可以在侧边栏点击【好劲抽商城】-右上角【我的】进入【我的】页面，分另点击【我的订单】 【地址管理】删除您的订单信息，收货地址以及联系人和联系电话。
            </p>
            <p>
                (3)进入好劲抽后，您可以在好劲抽APP首页-右上角【我的】进入【我的]页面，分别点击[我的订单]、【收货地址管理】删除您的订单信息，收货地址、联系人和联系电话，以及购票人信息。
            </p>
            <p>
                (4)进入好劲抽后，您可以在侧边栏点击【我的好友】进入我的好友页面，通过分别点击好友关注或粉丝，取消关注好友、或粉丝
            </p>
            <p>
                (5)当您使用第三方账号登录时您可以通过【我】-右上角【我的帐号】-【账号和绑定设置】解绑微信、手机、QQ、邮箱绑定设置，您也可以通过帐号设置修改密码，解绑同一手机号码下多个账号，管理登录设备
            </p>
            <p>
                4.2.2.在以下情形下，您可以按照本隐私政策第8条约定的联系方式链接向我们提出删除您个人信息的请求，但已做数据匿名化处理或法律法规另有规定的除外:
            </p>
            <p>
                (1)如果我们违反法律法规规定，收集、使用您的个人信息的:
            </p>
            <p>
                (2)如果我们违反了与您的约定，收集、使用您的个人信息的;
            </p>
            <p>
                (3)如果您同意我们收集、使用您的个人信息后又撤回同意:
            </p>
            <p>
                (4)如果我们处理个人信息的行为违反了与您的约定;
            </p>
            <p>
                (5)如果您不再使用我们的产品或服务，或您注销了账号;
            </p>
            <p>
                (6)如果我们不再为您提供产品或服务
            </p>
            <p>
                (7)如果您个人信息的处理目的已实现、无法实现或者为实现处理目的不再必要
            </p>
            <p>
                (8)如果您个人信息的保存期限已经届满。
            </p>
            <p>
                (9)法律法规等规定的其他情形
            </p>
            <p>
                4.2.3.关于您不再使用我们提供的产品或服务且注销好劲抽帐号后、以及我们终止部分或全部产品或服务后我们对您的个人信息的处理，我们将在本隐私政策其他专门条款中为您详细说明。
            </p>
            <p>
                <strong>4.2.4.您理解并同意:当您从我们的产品或服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。</strong>
            </p>
            <p>
                <strong>4.2.5.消息设置</strong>
            </p>
            <p>
                (1)iPhone消息设置需要您在设备设置通知权限进行更改。
            </p>
            <p>
                (2)聊天设置:若您不希望和某些人聊天，接收聊天信息，您可以通过好劲抽小程序【侧边栏】-【设置】-【消息设置】-【谁可以私信我】设置谁可以跟您聊天
            </p>
            <p>
                (3)黑名单设置:您可以通过好劲抽APP【侧边栏】-【设置】-【消息设置】-【用户黑名单/不再关注黑名单】进行设置，若您将列入黑名单的用户移除，移除后他人可以与您聊天、关注您或向您申请加好友。
            </p>
            <p>
                <strong>4.3.更正或补充个人信息</strong>
            </p>
            <p>
                4.3.1.当您发现我们处理的您的个人信息不准确或者不完整时，您有权要求我们作出更正或补充。您可以通过下列路径，自行对您的个人信息进行更正、补充:
            </p>
            <p>
                (1)进入好劲抽小程序后，您可以在[我】-点击头像-右上角【编辑资料】更正或修改您的头像、昵称、性别、生日、地区、职业、个性签名、兴趣爱好
            </p>
            <p>
                (2)您可以在好劲抽APP首页右上角【我的】-【收货地址管理】和【观影人管理】中更正地址和收货人或发表人信息，
            </p>
            <p>
                (3)您可以在侧边栏[好劲抽商城】一右上角【我的】-【收货地址管理】中更正地址和收货人信息。
            </p>
            <p>
                4.3.2.您也可以通过下述第8条联系方式与我们联系，我们对您的个人信息予以核实后，会及时更正、补充您的个人信息
            </p>
            <p>
                <strong>4.4获取个人信息副本</strong>
            </p>
            <p>
                若您需要您的个人信息的副本，您可以通过侧边栏。[隐私中心] 【隐私管理】【个人信息管理】[个人信息副本】填写您的邮箱地址，我们将把您的头像，明称等个人信息整理成文件并在72小时之内发送到您的邮箱
            </p>
            <p>
                在技术可行的前提下，符合国家网信部门规定会件的，我们还可按您的要求，将您的个人信息副本传输给您指定的第三方，
            </p>
            <p>
                <strong>4.5.撤回同意</strong>
            </p>
            <p>
                您可以通过以下方式改变或者撤回您授权我们处理您的个人信息的范围
            </p>
            <p>
                4.5.1.系统权限设置:我们在产品中提供系统权限设置功能，例如:在好劲抽APP【侧边栏】-[隐私中心]-【隐私管理】-[权限设置】中管理我们产品使用的系统权限授权(包括相机、麦克风、通讯录、位置、电话、存储日历等〕。拒绝授权后我们将不再收集与这些权限相关的信息，但不会影响我们此前基于您的授权而开意的个人信息处理，您可以阅读<span style="color: rgb(0, 112, 192);">《好劲抽应用权限说明》</span>，以了解好劲抽申请和使用系统权限的情况。
            </p>
            <p>
                4.5.2,内容推荐管理:您可以自主选择您喜欢的音视频及其他内容和调整您的内容喜好。例如:在好劲抽AP[侧边栏】-【设置中心】-隐私管理】-【个性化推荐管理】中设置是否允许我们根据历史记录等个人信息向您推荐音视频及其他内容
            </p>
            <p>
                4.5.3.广告管理:您可以自主是否决定接收个性化广告。例如:好劲抽APP【侧边栏】-【设置中心】-【隐私管理】-【广告管理】，如您关闭接收，您在我们产品内看到的广告数量可能不会过少，但与您的相关度将会降低。
            </p>
            <p>
                4.5.4.其他隐私设置:
            </p>
            <p>
                (1)将我推荐给可能认识的人:您有权选择不被推荐给他人。例如:在好劲抽【侧边栏】-[隐私中心]-【隐私管理】-【其他设置]中设置是否允许好友找到我，
            </p>
            <p>
                (2)其隐私设置功能，具体以产品实际提供为准。
            </p>
            <p>
                <strong>4.6.投诉举报</strong>
            </p>
            <p>
                如果您认为您的个人信息权利可能受到侵害，或者发现侵害个人信息权利的线索，您可以通过【侧边栏】-【客服反馈】-【意见反馈】，进入意见反馈界面与我们联系。您也可以通过下述第8条联系方式与我们联系。
            </p>
            <p>
                <strong>4.7.注销账号</strong>
            </p>
            <p>
                <strong>若您决定不再使用我们的产品及服务，您可以在[我】-点击头像-右上角【我的帐号】-【帐号和绑定设置】。【账号注销】中进行注销，在注销您的帐号之前，为保护帐号安全，确保此次注销帐号行为是您本人操作，我们会验证您的个人身份、常用设备等信息。我们特别提示，您注销账号的行为是不可逆的行为，一旦您注销您的帐号，我们将会删除或匿名化有关您账号的信息(但法律法规另有规定或监管部门另有要求的除外)，具体请参见《好劲抽用户帐号注销协议》</strong>。
            </p>
            <p>
                <strong>4.8,约束信息系统自动决策</strong>
            </p>
            <p>
                在某些业务功能中，我们可能仅依据信息系统。算法等在内的非人工自动决策机制作出决定，如果这些决定显著影响您的合法权益，您可以通过本隐私政策第8条的联系方式与我们联系，要求我们予以说明，并拒绝我们仅通过自动化决策的方式作出决定
            </p>
            <p>
                <strong>4.9.已故用户的个人信息保护</strong>
            </p>
            <p>
                用户不幸去世的，其近亲得为了自导的合法、正当利益。可以对已故用户的相关个人信息行使本隐私政策规定的查阅、复制、更正、划除等权利:已故用户生前另有安排的除外，个别情况下，我们可能需要验证近亲每身份后方可配合相关请求，届时我们会事先告知并征得近亲愿同意。
            </p>
            <p>
                <strong>4.10.如果我们终止服务或产品运营，我们会至少提前三十日以公告或其他合理、有效的方式向您发出通知，并在终止服务或运营后对您的信息进行删除或匿名化处理(但法律法规另有规定或监管部门另有要求的除外)。</strong>
            </p>
            <p>
                <strong>4.11,请您理解并知悉，根据法律法规等规定，在下述情况下，我们可能不会响应您关于查询、更正/惨改、删除、撤回同意、注销、个人信息副本获取等的请求</strong>
            </p>
            <p>
                (1)与我们遵循法律法规规定的义务相关的:
            </p>
            <p>
                (2)与国家安全、国防安全直接相关的
            </p>
            <p>
                (3)公共利益相关的;
            </p>
            <p>
                (4)与刑事侦查 起近 审判和执行判决等直接&nbsp;相关的
            </p>
            <p>
                (5)我们有充分证据表明您存在主观恶意或流用权利的:
            </p>
            <p>
                (6)出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的:
            </p>
            <p>
                (7)应您的请求将导致您或其他个人、组织的合法权益受到严重损害的:
            </p>
            <p>
                (8)涉商业秘密的
            </p>
            <p>
                (9)法法规等规定的其他情形，
            </p>
            <p>
                <br/>
            </p>
            <p>
                <strong>5,我们如何存储和保护您的个人信息</strong>
            </p>
            <p>
                <br/>
            </p>
            <p>
                5.1 个人信息的存储
            </p>
            <p>
                5.1.1.存储地点
            </p>
            <p>
                我们遵守法律法规规定，将在中华人民共和国境内运营中收集和产生的用户个人信息存储于中国境内。目前我们暂时不存在向境外提供您的个人信息的场景如我们因业务等需要，确察向中华人民共和国境外提供个人信息的，我们应具备法律规定的条件(通过国家网信部门组织的安全评估或按照国家网信部门制定的标准合同与境外接收方订立合同，约定双方的权利和义务等)，并将向您告知境外接收方的名称或者姓名，联系方式，处理目的，处理方式，个人信息的种类以及您向境外接收方行使个人信息主休权利的方式和程序等事项，取得您的单独同意，我们将采取必要措施，保障境外接收方处理个人信息的活动达到中国法律规定的个人信息保护标准。
            </p>
            <p>
                5.1.2.存储期限
            </p>
            <p>
                一般而言，我们仅为实现您的个人信息处理目的所必需的最短时间内存储您的个人信息，例如:当您使用我们的帐号注册与登录功能时，我们需要收集您的手机号码，且在您提供后并在您使用该功能期间，我们要持续为您保留您的手机号码，以向您正常提供该功能、保障您的账号和系统安全
            </p>
            <p>
                在您主动删除个人信息，注销账号或超出必要的存储期限后，我们会对您的个人信息进行删除或因名化处理，但法律法规另有规定的除外(例如:电子商务法》规定:商品和服务信息、交易信息保存时间自交易完成之日起不少于三年)
            </p>
            <p>
                我们判断个人信息的必要存储期限主要依据以下标准:(1)维护相应交易及业务记录，以应对您可能的查询或投诉:(2)保证我们为您提供服务的安全和质量:(3)您是否同意更长的留存期间
            </p>
            <p>
                当我们的产品或服务发生停止运营的情形时，我们将采取合适的方式(推送通知、站内信、公告等形式)通知您，并在合理的期限内划除或匿名化处理您的个人信息，法律法规另有规定的除外。
            </p>
            <p>
                <strong>5.2.信息安全保障措施</strong>
            </p>
            <p>
                521为保障您的个人信息安全，我们在合理的安全水平内使用各种安全保护持施来保障您的信息、防止数据遭到未经授权访问、公开披震、使用、修改、损坏或丢失，例如，我们使用加密技术(如SSL)名化处理等手段来保护您的个人信息。此外，我们还会不断加强安装在您设备活的软件的安全能力:例如，我们会在您的设备本地完成部分信息加密工作，以巩固安全传输:我们会了能您设备安装的应用信息和运行的进程信息，以预防病毒，木马等恶意程序。
            </p>
            <p>
                5.2.2.我们建立专门的管理制度、审批流程和组织确保信息安全，例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审查。
            </p>
            <p>
                5.2.3.我们鼓成我们的工作人员学习信息安全知识，提言个人信息安全保护意识，并定期或不定期对我们的工作人员进行信息安全培训
            </p>
            <p>
                5.2.4.若不幸发生个人信息泄露等安全事件，按照法律法规要求，我们会启动应急预案，阻止安全二件扩大，并及时告知您:安全事件的基本情况和可能的影响，我们已采取或将要采取的处否措施。您可自主防范和降低风险的建议、对您的补救措施等，我们将及时将事件相关情况以邮件、信团、电活，推送通知等方式告知您非以逐一告知用户时，我们会采取合理，有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况
            </p>
            <p>
                5.2.5,互联网环境并非百分之百安全，当出现下列非因我们过错而对您的信息造成泄毒及由此造成的损害结果，我们无需承担任何责任
            </p>
            <p>
                (1)任何由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营之不可抗力而造成的个人资料泄器、丢失，被盗用或被篡改等
            </p>
            <p>
                (2)在使用好劲抽服务的过程中能接到其它网站或因接受来自第三方的服务所造成之个人资料泄露及由此而导致的任何法律争议和后果
            </p>
            <p>
                <strong>(3)如您在使用好劲抽服务(例如留言、评论等)的过程中主动公开、上传、发布或向第三方提供您的个人信息的。其他用户可能会收集您的个人信息。</strong>
            </p>
            <p>
                <strong>4)其他用户发布的包含您或您未成年被监护人(子女)的肖像，声音、姓名、年龄等素材信息的内容。若您不同意我们在好劲抽产品和服务中展示前述内容，请想通过下述第8条联系方式和我们联系。</strong>
            </p>
            <p>
                <br/>
            </p>
            <p>
                <strong>6.我们如何保护未成年人的个人信息</strong>
            </p>
            <p>
                <br/>
            </p>
            <p>
                <strong>6.1.若您是18周岁以下的未成年人，在注册，使用好劲抽的产品或服务前，应事先取得父母或监护人的同意，并在父母或监护人指导下使用好劲抽服务。</strong>
            </p>
            <p>
                <strong>6.2.若您是14周岁以下的儿童，请您通知您的</strong>
            </p>
            <p>
                <br/>
            </p>
            <p>
                <strong>《隐私政策》，并在注册、登录、使用好劲抽的产品或服务以及提交个人信息前征得他们的同意和指导。</strong>
            </p>
            <p>
                <br/>
            </p>
            <p>
                <strong>7.本隐私政策如何更新</strong>
            </p>
            <p>
                <br/>
            </p>
            <p>
                7.1.随着我们的服务范围扩大，我们将适时更行本隐私政策的条款，更新内容构成本总私政策的一部分。若更新后的总私政策导致您的权利发生实质改变，我们将在吏新前通过显著位置提示或以推送消息，红点提示等方式通知您，若您继续使用我们的服务，即表示同意受更新后的隐私政笑约束。为避免您不能及时获知更新，请您经常阅讨本隐私政策。您可以在【侧边栏】-【关干好劲抽】-【隐私政策】或者&quot;【侧边栏】-《隐私中心》中查者更新后的《好劲抽隐私政策》。
            </p>
            <p>
                7.2更新后的《好劲抽总私政策》将以更新日期为生效日期，并取代之前的《好劲抽隐私改
            </p>
            <p>
                美》，
            </p>
            <p>
                <br/>
            </p>
            <p>
                <strong>8.如何与我们联系</strong>
            </p>
            <p>
                <br/>
            </p>
            <p>
                若您对本隐私政策有问题，意见，建议，或者与用户个人信息安全相关的投诉，举报，您可以通过书面函件(邮箱：340776280@qq.com)与我们进行联系，我们将在收到函件或电子邮件后十五日内予以回复。
            </p>
            <p>
                <br/>
            </p>
            <p>
                <strong>9.其他</strong>
            </p>
            <p>
                <br/>
            </p>
            <p>
                本隐私政策为《好劲抽用户服务协议》及相关办议的重要组成部分，本隐私政策内的名词定义参照《好劲抽用户服务协议》，适用于好劲抽公司相关服务。
            </p>
            <p>
                <br/>
            </p>
            <p style="text-align: right;">
                广州好劲抽计算机科技有限公司
            </p>
            <p>
                <br/>
            </p>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";

export default {
    data(){
        return{
            title:this.$route.meta.title
        }
    },
    components:{
        vHeader
    },
    created(){
    }
}
</script>
<style lang="less" scoped>
    .container{
        .article{
            padding: 0 10px;
            font-size: 16px;
            h2{
                text-align: center;
            }
        }
    }
</style>